import React, { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Button } from "../button"
import { IContractDetailItem } from "../../types/interfaces/IContractDetail"
import { IPreferredSupplierEquipmentRate } from "../../types/interfaces/IPreferredSupplierEquipmentRate"
import { PreferredSuppliersTable } from "../preferred-suppliers-table"

export interface IPreferredSuppliersModalProps {
    isOpen: boolean
    handlePreferredSupplierSelected: (supplier: any) => void
    closeModal: () => void
    items: IContractDetailItem[]
    preferredSupplierEquipmentRates: IPreferredSupplierEquipmentRate[]
    consolidatedSupplierEquipmentRate: null | IPreferredSupplierEquipmentRate
}

export const PreferredSuppliersModal = (props: IPreferredSuppliersModalProps) => {
    const [suppliersAvailable, setSuppliersAvailable] = useState<boolean>(false)
    const [cheapestIsPartner, setCheapestIsPartner] = useState<boolean>(false)
    const [hasReducedTransportPossible, setHasReducedTransportPossible] = useState<boolean>(false)
    const [showConsolidated, setShowConsolidated] = useState<boolean>(false)

    // Need to know if (a) there are suppliers available and (b) if the cheapest is a partner
    // so we can show the correct legend, and if there are potentially reduced transport rates
    useEffect(() => {
        if (props.preferredSupplierEquipmentRates.length > 0) {
            props.preferredSupplierEquipmentRates.map((equipmentRate: IPreferredSupplierEquipmentRate) => {
                if (equipmentRate.suppliers.length > 0) {
                    if (suppliersAvailable == false) {
                        setSuppliersAvailable(true)
                    }

                    const cheapestSupplier = equipmentRate.suppliers.find((supplier) => {
                        return supplier.cheapest
                    })

                    if (cheapestSupplier && cheapestSupplier.isPartner && cheapestIsPartner == false) {
                        setCheapestIsPartner(true)
                    }
                }

                // Is there reduced transport possibly available
                const reducedTransport = equipmentRate.suppliers.find((supplier) => {
                    return supplier.reducedTransportPossible
                })

                if (reducedTransport && hasReducedTransportPossible == false) {
                    setHasReducedTransportPossible(true)
                }
            })
        }
    }, [props.preferredSupplierEquipmentRates])

    useEffect(() => {
        if (props.consolidatedSupplierEquipmentRate === null) {
            setShowConsolidated(false)
        }
    }, [props.consolidatedSupplierEquipmentRate])

    return (
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10 font-normal"
                onClose={() => {}} // Empty method to prevent default behaviour closing this modal when another modal is closed
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                            >
                            <Dialog.Panel className="max-w-[80%] px-2 py-8 align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-xl font-bold leading-6 text-gray-900"
                                    >
                                    Partner Selection
                                </Dialog.Title>
                                <div className="mt-4 mx-4">
                                    {!showConsolidated && 
                                        props.preferredSupplierEquipmentRates.map((equipmentRate: IPreferredSupplierEquipmentRate, rateIndex: number) => (
                                            <PreferredSuppliersTable
                                                key={`equipment-rate-table-${rateIndex}`}
                                                equipmentRate={equipmentRate}
                                                handlePreferredSupplierSelected={props.handlePreferredSupplierSelected}
                                                rateIndex={rateIndex}
                                            />
                                        ))
                                    }
                                    {showConsolidated && props.consolidatedSupplierEquipmentRate && (
                                        <PreferredSuppliersTable
                                            equipmentRate={props.consolidatedSupplierEquipmentRate}
                                            handlePreferredSupplierSelected={props.handlePreferredSupplierSelected}
                                            rateIndex={99}
                                        />
                                    )}
                                    <div className="flex flex-row">
                                        <div className="flex flex-col justify-center w-1/3">
                                            {suppliersAvailable && (
                                                <div className="flex flex-row">
                                                    <div className="flex flex-col">
                                                        <div className="flex">
                                                            <div className="border border-green-600 p2 w-4 h-4"></div>
                                                            <div className="ml-2 leading-4">Lowest</div>
                                                        </div>
                                                    </div>
                                                    {!cheapestIsPartner && (
                                                        <div className="flex flex-col ml-4">
                                                            <div className="flex">
                                                                <div className="border border-amber-600 p2 w-4 h-4"></div>
                                                                <div className="ml-2 leading-4">Lowest Partner</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {hasReducedTransportPossible && (
                                                <div className="flex flex-row mt-2">
                                                    <div className="flex flex-col">
                                                        <p>* Reduced transport costs may be available</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex flex-col w-1/3 items-center justify-center">
                                            <Button outline className="mr-3" onClick={props.closeModal}>
                                                Close
                                            </Button>
                                        </div>
                                        <div className="flex flex-col justify-center w-1/3">
                                            {props.consolidatedSupplierEquipmentRate && (
                                                <div className="flex flex-row">
                                                    <div className="flex flex-col flex-grow items-end">
                                                        <div className="flex items-center">
                                                            <input
                                                                type="checkbox"
                                                                id="viewConsolidated"
                                                                name="viewConsolidated"
                                                                checked={showConsolidated}
                                                                onChange={(e) => {
                                                                    setShowConsolidated(e.target.checked)
                                                                }}
                                                                className=" w-[20px] h-[20px]"
                                                            />
                                                            <label
                                                                className="pl-2"
                                                                htmlFor="viewConsolidated"
                                                                >
                                                                Show consolidated view
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}