import React, { ReactChildren, useState } from "react"
import GridSystem from "../../../../tailwind-grid/grid"
import { Contract } from "../../../contract"
import ColSpanTwo from "../../../../tailwind-grid/col-span-two"
import RoundedButton from "../../../../rounded-button"
import ColSpanTen from "../../../../tailwind-grid/col-span-ten"
import ScissorLiftIcon from "../../../../icons/scissor-lift-icon"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby-link"

export interface ContractDetailProps {
  contractId: string
  children: ReactChildren
}

export const ContractDetail = ({ contractId, children }: any) => {
  const [filterState, setFilterState] = useState<any>(null)
  const showDetailPage = window.location.pathname.split("/").length === 4

  const backToFilteredContractsButton = () => {
    const state: any = useLocation().state    
    if (state && state.hasOwnProperty("hasFilters") && state.hasFilters) {

      if (!filterState) {
        setFilterState(state)
      }

      return (
        <div className="m-2 2xl:flex-grow">
          <RoundedButton
            className="w-full group border rounded-full flex items-center p-2 text-base font-bold hover:bg-tertiaryHover transition"
            icon={<ScissorLiftIcon />}
            title="Filtered Contracts"
            onClick={() => {
              navigate(`/solutions-dashboard?filters=${state.filters}`)
            }}
          />
        </div>
      )
    }
    return null
  }

  return (
    <GridSystem>
      <ColSpanTwo>
        <div className="md:sticky top-3 flex flex-wrap justify-items-start 2xl:space-y-3">
          {backToFilteredContractsButton()}
          <div className="m-2 2xl:flex-grow">
            <RoundedButton
              icon={<ScissorLiftIcon />}
              title="All Contracts"
              url="/solutions-dashboard"
            />
          </div>
        </div>
      </ColSpanTwo>
      <ColSpanTen>
        {showDetailPage ? (
          <Contract 
            contractId={contractId} 
            noContainer={true}
            filterState={filterState}
          />
        ) : (
          <>{children}</>
        )}
      </ColSpanTen>
    </GridSystem>
  )
}
