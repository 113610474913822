import React from "react"
import { Router } from "@reach/router"
import { Header } from "../../../components/header"
import { DashboardContainer } from "../../../components/dashboard-container"
import NotFound from "../../404"
import { ContractProvider } from "../../../components/pages/cx-dashboard/customer/store"
import { ContractDetail } from "../../../components/pages/solutions-dashboard/contract/contract-detail"
import CreatePO from "../../../components/pages/cx-dashboard/customer/customer-detail/contracts/contract/create-po"

const ContractDetailRoot = () => {
  return (
    <ContractProvider>
      <Header currentPage="solutions-dashboard-contract-detail-page" />
      <div className="mt-10">
        <DashboardContainer>
          <Router basepath="/solutions-dashboard/contract">
            <NotFound default />
            <ContractDetail path="/:contractId" contractId=":contractId">
              <CreatePO path="/pos/new" />
              <CreatePO path="/pos/:poId" />
            </ContractDetail>
          </Router>
        </DashboardContainer>
      </div>
    </ContractProvider>
  )
}
export default ContractDetailRoot
