import React from "react"
import StarRatings from "react-star-ratings"
import { IPreferredSupplierEquipmentRate } from "../../types/interfaces/IPreferredSupplierEquipmentRate"
import { IPreferredSupplierRate } from "../../types/interfaces/IPreferredSupplierRate"

export interface IPreferredSuppliersTableProps {
    equipmentRate: IPreferredSupplierEquipmentRate, 
    handlePreferredSupplierSelected: (supplier: any) => void
    rateIndex: number,
}

export const PreferredSuppliersTable = (props: IPreferredSuppliersTableProps) => {    
    const showMinimumWeek = 
        !props.equipmentRate.hasWeekChargePeriod && 
        props.equipmentRate.suppliers.filter((supplierRate: IPreferredSupplierRate) => {
            return supplierRate.minimumWeek
        }).length > 0

    // Gets a table cell formatted for the supplier rate, including a check for a suspect value (i.e. zero or less)
    const getSupplierRateTableCell = (value: number, className: string, suspect: boolean = false, marker: boolean = false) => {
        return suspect ? (
            <td className={`${className} text-amber-600`} title="Supplier not included in lowest selection due to suspected incorrect value">
                {`£${value.toFixed(2)}${marker ? "*": ""}`}<span className="font-bold ml-[2px]">!</span>
            </td>
        ) : (
            <td className={className}>
                {`£${value.toFixed(2)}${marker ? "*": ""}`}
            </td>
        )
    }

    // Gets a table cell with a tick or cross for boolean values
    const getBooleanTableCell = (value: boolean, className: string) => {
        return (
            <td className={className}>
                {value ? <>&#10003;</> : <>&#10005;</>}
            </td>
        )
    }

    // Gets the classes to apply to a variance table cell value (good vs bad)
    const getVarianceClassName = (variance: number, isExcludedForCustomer: boolean) => {
        return isExcludedForCustomer ? "" : variance <= 0 ? 'text-green-600' : 'font-bold text-red-600'
    }

    // Gets a calculated variance between the supplier and contract rate
    const getVariance = (supplierRate: number, contractRate: number, isExcludedForCustomer: boolean) => {
        const variance = supplierRate - contractRate
        const className = getVarianceClassName(variance, isExcludedForCustomer)
        return (
            <span className={className}>{`${variance < 0 ? (variance * -1).toFixed(2) : variance.toFixed(2)}`}</span>
        )
    }

    // Gets a calculated variance percentage between the supplier and contract rate
    const getVariancePercentage = (supplierRate: number, contractRate: number, isExcludedForCustomer: boolean) => {
        const percentageDifference = (100 - ((supplierRate / contractRate) * 100)) * -1
        const className = getVarianceClassName(percentageDifference, isExcludedForCustomer)
        return (
            <span className={className}>{`${percentageDifference < 0 ? (percentageDifference * -1).toFixed(2) : percentageDifference.toFixed(2)}`}</span>
        )
    }

    return (
        <div key={`equipment-rate-${props.rateIndex}`}>
            {props.equipmentRate.suppliers && props.equipmentRate.suppliers.length > 0 && (
                <div className="mb-8">
                    <h3 className="text-left text-l font-bold leading-6 text-gray-900 mb-2">
                        <span className="uppercase">{props.equipmentRate.equipmentCode}</span> delivering to {props.equipmentRate.postcode}
                    </h3>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto">
                            <div className="inline-block w-full align-middle">
                                <div className="border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-300">
                                            <tr>
                                                <th
                                                    rowSpan={2}
                                                    className="px-2 text-left">
                                                        Partner
                                                </th>
                                                <th
                                                    rowSpan={2}
                                                    className="px-2">
                                                        Rating
                                                </th>
                                                <th
                                                    colSpan={4}
                                                    className="px-2 border-l-2 border-gray-400">
                                                        Hire Period {props.equipmentRate.chargePeriod}
                                                </th>
                                                <th
                                                    colSpan={4}
                                                    className="px-2 border-l-2 border-gray-400">
                                                        Transport
                                                </th>
                                                <th
                                                    colSpan={4}
                                                    className="px-2 border-l-2 border-gray-400">
                                                        Total
                                                </th>
                                                {showMinimumWeek && (
                                                    <th
                                                        rowSpan={2}
                                                        className="px-2 border-l-2 border-gray-400">
                                                            Min. Week?
                                                    </th>
                                                )}
                                                <th
                                                    rowSpan={2}
                                                    className="border-l-2 border-gray-400">
                                                        &nbsp;
                                                </th>
                                            </tr>
                                            <tr>
                                                <th className="border-l-2 border-gray-400">Supplier</th>
                                                <th>Contract</th>
                                                <th>£ +/-</th>
                                                <th>% +/-</th>
                                                <th className="border-l-2 border-gray-400">Supplier</th>
                                                <th>Contract</th>
                                                <th>£ +/-</th>
                                                <th>% +/-</th>
                                                <th className="border-l-2 border-gray-400">Supplier</th>
                                                <th>Contract</th>
                                                <th>£ +/-</th>
                                                <th>% +/-</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.equipmentRate.suppliers.map((supplier: IPreferredSupplierRate, index: number) => (
                                                <tr
                                                    key={`preferred-partner-${index}`}
                                                    className={`even:bg-gray-100 odd:bg-white${supplier.isExcludedForCustomer ? " text-[#BBBBBB]" : supplier.cheapestPartner && !supplier.cheapest ? " border border-amber-600" : supplier.cheapest ? " border border-green-600" : ""}`}
                                                >
                                                    <td className="px-2 text-left whitespace-nowrap">
                                                        {supplier.supplierName}
                                                    </td>
                                                    <td className="px-2">
                                                        <div className="flex flex-row justify-center">
                                                            <div className="flex flex-col w-[90px] mt-[-2px] mx-2">
                                                                <StarRatings
                                                                    numberOfStars={5}
                                                                    starRatedColor="gold"
                                                                    starDimension="18px"
                                                                    starSpacing="0px"
                                                                    rating={supplier.isExcludedForCustomer ? 0 : supplier.starRating}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                {supplier.rating.toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {getSupplierRateTableCell(supplier.chargePeriodRate, "px-2 border-l-2 border-gray-400", supplier.chargePeriodRateSuspect)}
                                                    <td className="px-2">
                                                        {`£${props.equipmentRate.chargeAmount.toFixed(2)}`}
                                                    </td>
                                                    <td className="px-2">
                                                        {getVariance(supplier.chargePeriodRate, props.equipmentRate.chargeAmount, supplier.isExcludedForCustomer)}
                                                    </td>
                                                    <td className="px-2">
                                                        {getVariancePercentage(supplier.chargePeriodRate, props.equipmentRate.chargeAmount, supplier.isExcludedForCustomer)}
                                                    </td>
                                                    {getSupplierRateTableCell(supplier.transportTotal, "px-2 border-l-2 border-gray-400", supplier.transportSuspect, supplier.reducedTransportPossible)}
                                                    <td className="px-2">
                                                        {`£${props.equipmentRate.transportCharge.toFixed(2)}`}
                                                    </td>
                                                    <td className="px-2">
                                                        {getVariance(supplier.transportTotal, props.equipmentRate.transportCharge, supplier.isExcludedForCustomer)}
                                                    </td>
                                                    <td className="px-2">
                                                        {getVariancePercentage(supplier.transportTotal, props.equipmentRate.transportCharge, supplier.isExcludedForCustomer)}
                                                    </td>
                                                    {getSupplierRateTableCell(supplier.chargePeriodAndTransportTotal, "px-2 border-l-2 border-gray-400", supplier.chargePeriodRateSuspect || supplier.transportSuspect, supplier.reducedTransportPossible)}
                                                    <td className="px-2">
                                                        {`£${(props.equipmentRate.chargeAmount + props.equipmentRate.transportCharge).toFixed(2)}`}
                                                    </td>
                                                    <td className="px-2">
                                                        {getVariance(supplier.chargePeriodAndTransportTotal, props.equipmentRate.chargeAmount + props.equipmentRate.transportCharge, supplier.isExcludedForCustomer)}
                                                    </td>
                                                    <td className="px-2">
                                                        {getVariancePercentage(supplier.chargePeriodAndTransportTotal, props.equipmentRate.chargeAmount + props.equipmentRate.transportCharge, supplier.isExcludedForCustomer)}
                                                    </td>
                                                    {showMinimumWeek && getBooleanTableCell(supplier.minimumWeek, "px-2 border-l-2 border-gray-400")}
                                                    <td className="px-4 border-l-2 border-gray-400">
                                                        {!supplier.isExcludedForCustomer && (
                                                            <button
                                                                className="px-4 py-1 my-1 text-sm text-white uppercase border-transparent rounded-full bg-horizonred hover:bg-filteringPillsHover hover:text-white"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    props.handlePreferredSupplierSelected(supplier)
                                                                }}
                                                                >
                                                                    Select
                                                            </button>
                                                        )}
                                                        {supplier.isExcludedForCustomer && (
                                                            <div className="flex justify-center my-1">
                                                                <div className="flex items-center" title="This supplier should not be used for this customer">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="mr-2">
                                                                        <g stroke="#ffa300" strokeWidth="2.23">
                                                                            <circle fill="transparent" cx="10" cy="10" r="8.5652" />
                                                                            <path d="M10,4.188V11.657M10,13.438V15.813" />
                                                                        </g>
                                                                    </svg>
                                                                    <div className="text-gray-900">Excluded</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(!props.equipmentRate.suppliers || props.equipmentRate.suppliers.length === 0) && (
                <div
                    key={`equipment-rate-${props.rateIndex}`}
                    className="mb-8">
                        <h3 className="text-left text-l font-bold leading-6 text-gray-900 mb-2">
                            No supplier rates available for <span className="uppercase">{props.equipmentRate.equipmentCode}</span> delivering to {props.equipmentRate.postcode}
                        </h3>
                </div>
            )}
        </div>
    )
}